.react-select-container {
    color: black;
    height: fit-content;
    width: 200px;
    min-width: 100%;
}
#react-select-3-listbox {
    color: black;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: aliceblue;
}
.react-select-container .kuro-search-select__menu {
    min-width: 100%;
   width: max-content;
   white-space: nowrap;
}
.react-select-container input::after {
    color: rgba(0, 0, 0, .2);
}