.background{
    border: 2px solid #fff;
    padding: 10px;
    width: 100%;
    margin-bottom: 20px;
    background-color: rgb(20, 19, 19);
}
.pre-builts .builds:last-child{
    margin-bottom: 50px;
}
.variation {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    white-space: nowrap;
}
@media only screen and (max-width: 37.5em) {    
    
      .search-results {
        padding: 0;
      }
     .background{
        display: block;
      }
 }