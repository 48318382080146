button.disabled {
    background-color: #ccc;
    color: #aaa;
}
button.disabled:hover {
    background-color: #ccc;
    color: #aaa;
}
.preset_wrapper{
    display: flex;
    flex-direction: column-reverse;

    gap: 20px;
}
.preset_form{
    display: flex;
}
.preset_head {
    margin-bottom: 30px;
}
.preset_head select {
    height: 42px;
    width: 200px;
}
.preset_content {
    padding-top: 30px;
    height: 400px;
    overflow: auto;
    margin-bottom: 30px;
}
.preset_content thead {
    position: absolute;
    top: 0;
}
.preset_content th {
    padding: 10px 5px;
}
.preset_body textarea,
.preset_body select,
.preset_body input {
    width: 100%;
}
.preset_body textarea {
    border: 2px solid #000;
}
.preset_body .large {
    width: 300px;
}
.preset_body .medium {
    width: 200px;
}
.preset_body .small {
    width: 100px;
}
.preset_data textarea,
.preset_data select,
.preset_data input {
    width: 89%;
}
.preset_data table td input[type = "radio"] {
    width: 16px;
    margin:-4px 7px;
}
.preset_wrapper{
    margin-top: 30px;
}
.preset_wrapper .vendor_table{
    margin: 0 20px;
    width: 100%;
}
.preset_wrapper .vendor_table tr{
    width: 100%;
}
@media only screen and (min-width: 48em) {
    .preset_data td textarea {
        width: 600px;
    }
    .preset_wrapper{
       display: grid;
    }
}