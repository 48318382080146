.profile input:disabled{
    background: none;
    border: none;
    color: aliceblue;
}
.profile .otp{
    display: grid;
    grid-template-columns: auto 100px;
    gap: 10px;
}
.profile .otp button{
    min-width: 90px;
}