
.hero_table {
    border-bottom: 1px solid #aaa;
    border-right: 1px solid #aaa;
    max-width: 100%;
    width: 500px;
    text-align: left;
}

@media only screen and (min-width: 32em) {
    .input_mob {
        width: 700px;
        max-width: 100%;
      }
}