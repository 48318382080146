.user_auth {
    background: #000;
    display: grid;
    min-height: 100vh;
    grid-template-columns: 2fr 1fr;
}
.user_auth .auth_left {
    align-items: end;
    border-right: 8px solid #ed2224;
    display: grid;
    gap: 30px;
    justify-items: center;
    padding: 30px;
}
.user_auth .auth_left .txt {
    color: #fff;
    text-align: center;
}
.user_auth .auth_left .warning {
    border: 1px solid #ed2224;
    border-radius: 5px;
    color: #fff;
    padding: 20px;
}
.user_auth .auth_left .txt p {
    font-weight: 400;
    font-size: 13px;
}
.user_auth .auth_left .logo svg {
    height: 180px;
    width: 360px;
}
.user_auth .auth_right {
    align-items: end;
    background-color: #151515;
    display: grid;
    justify-items: center;
    padding: 30px;
}
.user_auth .auth_right .ack {
    font-size: 12px;
    font-style: italic;
}
.user_auth .title {
    margin-bottom: 25px;
    width: 100%;
}
.user_auth .title svg {
    fill: #4d4c4c;
    margin-right: 10px;
}
.user_auth .med {
    display: none;
}
.user_auth .auth_details {
    width: 250px;
}
.user_auth .login_details,
.user_auth .otp-details {
    width: 250px;
}
.user_auth .login_error {
    color: red;
    display: block;
    font-size: 12px;
    margin-top: 10px;
}
.user_auth.login .forgot {
    margin: 8px 0 0;
}
.user_auth .hide {
    display: none;
}
.user_auth.login .login_details #login {
    margin-right: 20px;
}
.user_auth .field_wrapper {
    margin: 20px 0;
    position: relative;
}
.user_auth .field_wrapper label {
    font-size: 14px;
    height: 40px;
    left: 0;
    padding: 12px 14px;
    pointer-events: none;
    position: absolute;
    text-align: left;
    top: 0;
    transition: top 0.1s linear;
    width: 100%;
}
.user_auth .field_wrapper.focus label {
    color: #00bceb;
}
.user_auth .field_wrapper.filled label,
.user_auth .field_wrapper.focus label {
    background: #151515;
    font-size: 10px;
    height: 16px;
    left: 15px;
    padding: 3px 5px;
    top: -8px;
    width: auto;
    z-index: 1;
}
.user_auth .field_wrapper input {
    background: transparent;
    border: 2px solid #4d4c4c;
    border-radius: 4px;
    color: #dce0e6;
    font-size: 14px;
    height: 40px;
    outline: none;
    padding: 10px 12px;
    width: 250px;
}
.user_auth .field_wrapper.focus input {
    border: 2px solid #025879;
}
.user_auth .field_wrapper .warning {
    color: red;
    display: none;
    font-size: 10px;
    margin: 5px 0 0 5px;
}
.user_auth .field_wrapper.warn .warning {
    display: block;
}
.user_auth .field_wrapper .toggle-pwd {
    height: 24px;
    position: absolute;
    right: 10px;
    top: 8px;
    width: 24px;
}
.user_auth .field_wrapper .toggle-pwd svg {
    display: none;
    cursor: pointer;
    fill: #4d4c4c;
}
.user_auth .field_wrapper .toggle-pwd svg.show,
.user_auth .field_wrapper.show-pwd .toggle-pwd svg.hide {
    display: block;
}
.user_auth .field_wrapper.show-pwd .toggle-pwd svg.show {
    display: none;
}
.user_auth .otp-details .back {
    cursor: pointer;
}
.txt-link,
button.txt-link {
    background: 0 0;
    border: none;
    color: #00bceb;
    cursor: pointer;
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-align: start;
    font-size: 14px;
}
.txt-link:hover,
.txt-link:focus,
button.txt-link:hover,
button.txt-link:focus {
    color: #007493;
    text-decoration: none;
}
.txt-link:active,
button.txt-link:active {
    color: #005073;
}

@media only screen and (min-width: 37.5em) {
    .user_auth {
        width: 100%;
    }
    .user_auth .med {
        display: inline-block;
        padding: 5% 10%;
        vertical-align: top;
        width: 49%;
    }
}

@media only screen and (min-width: 48em) {
    .user_auth {
        box-shadow: 0px 3px 8px 1px #000;
    }
    .user_auth .med {
        padding: 40px 50px;
        text-align: center;
        vertical-align: top;
        width: 50%;
    }
    .user_auth .med svg {
        width: 200px;
    }

}