.itc-gst{
    width: 80%;
    height: 100%;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    border: 2px solid #ccc;
    border-radius: 8px;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.02);
}
.itc-gst .file-input{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.itc-gst .file-input h3{
    color: #fbfbfb;
    margin-top: 10px;
}
.itc-gst input[type="file"]{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.itc-gst-wrapper .info{
    display: flex;
    flex-direction: row;
    gap: 30px;
    height: fit-content;
}
.itc-gst-wrapper .info > div {
    display: flex;
    gap: 5px;
    color: #ccc;
    line-height: 25px;
}
.itc-gst-wrapper .info .box{
    display: inline-block;
    width: 25px;
    height: 25px;
}
.itc-gst-wrapper .info .box.red{
    background-color: red;
}
.itc-gst-wrapper .info .box.green{
    background-color: green;
}
.itc-gst-wrapper .info .box.yellow{
    background-color: yellow;
}
