.indent-list .checkbox.checkbox  {
    width: 35px;
    height: 35px;
    margin: 0;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    background: transparent;
    transition: 0.3s;
    padding: 0;
    min-width: 35px;
}
.indent-list button{
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
}
.indent-list button svg{
    width: 20px;
    height: 20px;
}
.indent-list .sticky-btn{
    position: sticky;
    top: 10px;
    z-index: 1;
   
}
.indent-list .fixed-btn{
    height: fit-content;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    z-index: 1;
}
.indent-list .fixed-btn:disabled{
    cursor: not-allowed;
    opacity: 0.5;
}
.indent-list input[type="number"] {
    width: fit-content;
    max-width: 100px;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}
.indent-list input[type="checkbox"] {
    width: 15px;
}
.indent-list input[type="text"] {
    width: fit-content;
    max-width: 150px;
}
.indent-list input:read-only {
    color: #fff;
    background: transparent;
    border: none;
}
.indent-list select{
    max-width: 150px;
}
.indent-list select:disabled{
    background-color: transparent;
    color: #fff;
    border: none;
}
.indent-list .delete-btn {
    background-color: red;
    color: #fff;
    min-width: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.indent-list .delete-btn:hover {
    background-color: #db0b5b;
}
.indent-list .delete-btn > svg{
    width: 15px;
    height: 15px;
    fill: #fff;
}
.indents{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
}