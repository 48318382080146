.prod-list {
    padding: 30px 0;
}
.prod-list table {
    color: #ccc;
    margin-bottom: 30px;
    border-collapse: collapse;
}
.prod-list input {
    width: 100px;
}
.prod-list input[type="radio"] {
    width: 30px;
}
.prod-list textarea {
    width: 100%;
}
.search_wrap, 
.search_wrap2 {
    display: grid;
    gap: 20px;
    margin: 30px 0;
}
.product_finder {
    display: grid;
    gap: 20px;
    grid-template-columns: 150px  200px auto 200px;
    align-items: center;
}
.search-results {
    padding: 30px;
}
.prod-list .details input,
.products .details input {
    width: 500px;
}
.prod-span {
    cursor: pointer;
    display: inline-block;
    line-height: 0;
}
.title_wrap {
    display: block;
    gap: 30px;
}
.title_wrap svg {
    margin: 0 0 17px 8px;
    width: 15px;
}
textarea,
input,
select,
.react-datepicker-wrapper {
    width: 100%;
}
.title_wrap p {
    font-size: 20px;
}
.product_finder{
    display: grid;
    align-items: center;
}
@media only screen and (min-width: 37.5em) {
    .title_wrap p {
        font-size: 34px;
        text-align: center;
    }
    .title_wrap svg {
        margin: 13px 0 38px 20px;
        width: 25px;
          
    }
}
@media only screen and (min-width: 64em) {
    .search_wrap {
        grid-template-columns: 150px auto 420px;
        align-items: center;
    }
    .search_wrap2 {
        grid-template-columns: 150px 150px auto 240px;
        align-items: center;
    }
    .title_wrap {
        display: flex;
        width: 100%;
        gap: 10px;
    }
    .col_2 {
        display: flex;
        width: 100%;
        grid-gap: 10px;
      }
}