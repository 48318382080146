.estimate-preview {
    background: #fff;
    padding: 35px 80px;
    width: 210mm;
    height: 297mm;
}
.estimate-preview b {
    font-weight: 400;
}
.estimate-preview a {
    color: #000;
}
.estimate-preview .es-header {
    border-bottom: 4px solid #000;
    padding: 0 0 10px;
    width: 100%;
}
.estimate-preview .es-header .left {
    display:inline-block;
    margin-right: 20px;
    width: 80px;
}
.estimate-preview .es-header .left svg {
    height: 80px;
    width: 80px;
}
.estimate-preview .es-header .right .details p {
    font-size: 11px;
}
.estimate-preview .es-header .right {
    display: inline-block;
    text-align: center;
    width: calc(100% - 150px);
}
.estimate-preview .es-header .right svg {
    width: 400px;
}
.estimate-preview .es-header .es-text-logo {
    display: inline-block;
    line-height: 0;
    position: relative;
}
.estimate-preview .es-header .es-text-logo .caption {
    font-size: 10px;
    font-weight: 300;
    position: absolute;
    right: 0;
    top: -8px;
}
.estimate-preview .es-body {
    height: 860px;
}
.estimate-preview .es-body .es-main {
    height: 720px;
}
.estimate-preview .es-body .signatory {
    text-align: right;
}
.estimate-preview .es-body .notes p {
    font-size: 13px;
}
.estimate-preview .es-body .signatory p:first-child {
    margin-bottom: 40px;
}
.estimate-preview .es-footer .es-disclaimer {
    margin-bottom: 20px;
}
.estimate-preview .es-footer .es-disclaimer p,
.estimate-preview .es-footer .es-disclaimer b,
.estimate-preview .es-footer .es-disclaimer a {
    font-size: 10px;
    margin: 0;
}
.estimate-preview .es-footer .contact-us {
    border-top: 4px solid #000;
    padding-top: 10px;
    text-align: center;
}
.estimate-preview .es-footer .contact-us p {
    font-size: 14px;
}
.entity {
    display: grid;
    grid-template-columns: 110px 1fr;
    gap: 10px;
    margin: 0 0 30px;
    width: 300px;
}
.b2b {
    display: grid;
    grid-template-columns: 40px 1fr;
    gap: 10px;
    margin: 0 0 30px;
    width: 300px;
}
.b2b input {
    margin: 0;
    width: 20px;
}
.estimate_details .build_comps {
    display: block;
    margin-top: 5px;
    padding: 0 0 0 20px;
}
.build select {
    min-width: 100%;
    width: 160px;
}
.estimate_product_details select {
    width: 80px;
}
.estimate_details .logo {
    width: 60px;
    height: 60px;
}
.buildtype {
    width: auto;
}
.checkbox {
    width: 20px;
    position: absolute;
    margin: 5px 5px;
}
.estimate_grid {
    display: grid;
    grid-template-columns: 1fr;
}
.col_mob {
    display: flex;
    gap: 30px;
}
@media only screen and (min-width: 64em) {
    /* .checkbox {
        margin: 5px 0;
    } */
    .col_mob {
        flex-direction: row;
        align-items: end;
        gap: 0;
    }
    .estimate_grid {
        grid-template-columns: 1fr 400px;
        gap: 30px;
    }
}