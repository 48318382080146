.business-group-container {
    color: #fff;
}
.business-group-container > button{
    margin-bottom: 20px;
}
.bussiness-group-form > div{
    display: grid;
    grid-template-columns: 180px 1fr;
    border: 1px solid #ccc;
    width: fit-content;
    min-width: 500px;
    padding: 6px;
    position: relative;
}
.bussiness-group-form > div::after{
    content: '';
    position: absolute;
    top: 0;
    left: 180px;
    height: 100%;
    width: 1px;
    background: #ccc;
}
.bussiness-group-form > div > input {
    max-width: 400px;
    height: 18px;
    border-left: 1px solid #ccc;
}
.bussiness-group-form > div > label {
    font-size: 16px;
    max-width: 150px;
}
.bussiness-group-form > div:last-child {
    margin-top: 20px;
    border: none;
    display: flex;
    gap: 10px;
}
.bussiness-group-form > div:last-child::after {
    display: none;
}
.entity_container table{
    margin-bottom: 0;
}