.tabs {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    justify-content: flex-start;
}
.tab {
    align-self: flex-end;
    background: #1d1e23;
    border: 1px solid #4d4c4c;
    border-bottom: none;
    cursor: pointer;
    padding: 15px 30px;
}
.tab:hover {
    background: #1d1e23;
}
.tab.active {
    border-top: 3px solid #dc3e58;
    padding: 20px 30px;
    z-index: 1;
}
.tab-panel {
    border: 1px solid #4d4c4c;
    display: none;
    padding: 10px;
    position: relative;
    top: -1px;
    width: 100%;
}
.tab-panel.active {
    display: block;
}
.tabArea {
    border-bottom: 1px solid #ccc;
}
@media only screen and (max-width: 64em) {
    .tabs {
        display: flex;
        grid-template-columns: repeat(5, auto);
        justify-content: flex-start;
    }
    .tabArea {
        border-bottom: 1px solid #ccc;
    }
    .tab {
        align-self: flex-end;
        background: #1d1e23;
        border: 1px solid #4d4c4c;
        border-bottom: none;
        cursor: pointer;
        padding: 15px 30px;
        width: 100%;
    }
    .tab-panel {
        border: 1px solid #4d4c4c;
        display: none;
        padding: 10px;
        position: relative;
        top: -1px;
        width: 100% !important;
    }
    .tab.active {
        border-top: 3px solid #dc3e58;
        padding: 20px 30px;
        z-index: 1;
    }
    .tab-panel.active {
        display: block;
        width: 100% !important;
    }
}
/* Styles for tabs-mobile view */
@media screen and (max-width: 64em) {
    /* Styles for tabs */
    .tabs {
        display: block;
        width: 100%;
    }
    /* Styles for individual tabs */
    .tab {
        display: block;
        border-bottom: 1px solid #4d4c4c;
    }
    /* Styles for the active tab */
    .tab.active {
        border-top: none;
        border-bottom: 3px solid #dc3e58;
        padding: 20px 30px;
    }
    /* Styles for the tab panel */
    .tab-panel {
        border: 1px solid #4d4c4c;
        display: none;
        padding: 10px;
        position: relative;
        top: -1px;
    }
    /* Additional styles for the active tab */
    .tab.active {
        border-top: 3px solid #dc3e58;
        padding: 20px 30px;
        z-index: 1;
    }
    /* Display the active tab panel */
    .tab-panel.active {
        display: block;
    }
}
/*  Small   = 600 to 767 pixels */
/* Styles for tablet view */
@media screen and (min-width: 48.063em) and (max-width: 63.938em) {
    .tabs {
        display: block;
        padding: 15px 30px;
        width: 100%;
    }
    .tab {
        display: block;
        border-bottom: 1px solid #4d4c4c;

    }
    .tab.active {
        border-top: none;
        border-bottom: 3px solid #dc3e58;
        padding: 20px 30px;
    }
    .tab-panel {
        border: 1px solid #4d4c4c;
        display: none;
        padding: 10px;
        position: relative;
        top: -1px;
    }
    .tab.active {
        border-top: 3px solid #dc3e58;
        padding: 20px 30px;
        z-index: 1;
    }
    .tab-panel.active {
        display: block;
    }
}

/* Styles for mobile view   Small   = 600 to 767 pixels*/
@media only screen and (max-width: 48em) {
    .tabs {
        grid-template-columns: repeat(5, auto);
        justify-content: flex-start;
    }
    .tabArea {
        border-bottom: 1px solid #ccc;
    }
    .tab {
        align-self: flex-end;
        background: #1d1e23;
        border: 1px solid #4d4c4c;
        border-bottom: none;
        cursor: pointer;
        padding: 15px 30px;
        width: 100%;
    }
    .tab-panel {
        border: 1px solid #4d4c4c;
        display: none;
        padding: 10px;
        position: relative;
        top: -1px;
        width: 100% !important;
    }
    .tab-panel.active {
        display: block;
        width: 100% !important;
    }
    .tab.active {
        border-top: 3px solid #dc3e58;
        padding: 20px 30px;
        z-index: 1;
    }
    .main {
        padding: 20px;
        margin: 0 auto;
    }
}