
.attendence-dates{
    display: flex;
    width: auto;
    justify-content: center;
    background-color: #40424b;
    color: #ffffff;margin-bottom: 30px;
 }
 .attendence-dates > label{
   position: relative;
   top: 0;
   left:0;
   font-size: 22px;
   background-color: #40424b;
   font-family: Lato;
   padding: 12px;
 }
 .attendence-dates > input[type=checkbox]{
     background-color: white;
     height: 0em;
     width: 0em;
     background: none;
    }
 .attendence-dates > input[type=checkbox]:checked + label{
  color: #40424b;
  background-color: white;
 }
 .attendence-dates > label:hover{
   transform: scale(102%);
 }