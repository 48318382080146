.search_wrapper{
    position: relative;
    width: 100%;
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
}
.search_wrapper .search_input, .search_wrapper .search_results{
    width: 100%;
    height: 100%;
}
.search_wrapper .search_input{
    display: grid;
    grid-template-columns: 1fr auto 40px;
    background-color: #1d1e23;
    border-radius: 18px;
    color: #fff;
    padding-left: 20px;
}
.search_wrapper .search_input input{
    color: #fff;
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
    font-size: 16px;
    background: transparent;
}
.search_close_icon{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #292b31;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
}

.search_input svg{
    cursor: pointer;
    height: 30px;
    width: 30px;
    fill: #fff;
}

.search_filter{
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #292b31;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    padding: 5px 10px;
}

.search_filter select{
    background-color: transparent;
    color: gray;
    border: none;
    outline: 0;
    font-size: 16px;
    width: 100px;
}
.search_wrapper .search_results{
    position: absolute;
    left: 0;
    top: 100%;
    min-height: 200px;
    height: auto;
    max-height: 500px;
    background-color: #1d1e23;
    border-radius: 0 0 18px 18px;
    overflow-y: auto;
    color: #fff;
    z-index: 10;
    padding: 10px;
}
.search_wrapper .search_results .search_result_btn{
    position: sticky;
    bottom: 0;
    right: 0;
    height: 30px;
    width: fit-content;
    margin: 0 auto;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #292b31;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}
.search_wrapper .search_result{
    margin-bottom: 10px;
    border-bottom: 1px solid #292b31;
}
.search_wrapper .search_results .search_result:last-child{
    border-bottom: 0;
}
.search_wrapper .search_results h5{
    margin: 5px 10px; 
    text-transform: capitalize;
}

.search_wrapper .search_results::-webkit-scrollbar{
    width: 5px;
}
.search_wrapper .search_results::-webkit-scrollbar-track{
    background-color: #292b31;
    border-radius: 5px;
}
.search_wrapper .search_results::-webkit-scrollbar-thumb{
    background-color: #292b31;
    border-radius: 5px;
}

.search_wrapper .search_overlay{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
}
.search_result_item{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    cursor: pointer;
    border-left: 1px solid #292b31;
}
.search_result_item:hover{
    background-color: #292b31;
}
.search_result_item p{
    margin-bottom: 0;
    text-transform: capitalize;
}

 /* FOR SEARCH RESULT PAGE */
 .search_wrapper.page{
    padding: 0;
    width: 100%;
    max-width: none;
    overflow: visible;
    height: 100%;
    flex-direction: column;
    gap: 30px;
 }
 .search_wrapper.page .search_input{
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 10px 20px;
     background-color: #292b31;
     color: #fff;
     font-size: 16px;
     gap: 10px;
    }
    .search_wrapper.page .search_input input{
        width: 100%;
        height: 100%;
        border: 0;
        outline: 0;
        background: transparent;
    }
    .search_wrapper.page .search_results{
        position: static;
        max-height: none;
        height: 100%;
        z-index: 1;
    }


@media screen and (max-width: 64rem) {
    .search_wrapper .search_input{
       /* display: none; */
    }
    .header .banner1.sb_open .search_wrapper{
    display: none;
}
}