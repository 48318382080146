.inner-wrapper {
    height: 200px;
    width: 300px;
    position: absolute;
    background-color: rgba(0, 0, 0, .5);
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 4px;
}
.inner-wrapper > p {
    text-align: center;
    margin-bottom: 15px;
}