table {
    margin-bottom: 10px;
    border-spacing: 2px 5px;
}
table.border {
    border-left: 1px solid #aaa;
    border-top: 1px solid #aaa;
    border-collapse: collapse;
    margin-bottom: 30px;
}
table.minimum_height {
    height: fit-content;
}
.wide_table table {
    width: 1100px;
}
table.bg_black {
    background: #000;
}
table th {
    background: #100e17;
    color: #ccc;
    font-weight: 600;
}
table.even_odd tr:nth-child(2n + 1) {
    background: #576463;
    color: #fff;
}
table.even_odd tr:nth-child(2n) {
    background: #4d4c4c;
    color: #fff;
}
table.border th,
table.border td {
    border-bottom: 1px solid #aaa;
    border-right: 1px solid #aaa;
    min-width: 50px;
}
table.no-border,
table.no-border > thead > th,
table.no-border > thead > td,
table.no-border > tbody > td {
    border: none;
}
table th,
table td {
    padding: 5px;
}
table th,
table td,
table td a {
    font-size: 12px;
}
table.ph_20 th,
table.ph_20 td {
    padding-right: 20px;
    padding-left: 20px;
}
table td input.small,
table td select.small,
table td textarea.small {
    width: 100px;
}
table td input.medium,
table td select.medium,
table td textarea.medium {
    width: 200px;
}
table td input.large,
table td select.large,
table td textarea.large {
    width: 300px;
}
table td textarea.x-large,
table td select.x-large,
table td input.x-large {
    width: 100%;
}
table td input.noarrows {
    -moz-appearance: textfield;
}
table td input[type="checkbox"] {
    margin-right: 20px;
}
table td .prod-span {
    cursor: pointer;
    padding: 0 2px;
}
table td p,
table td i {
    font-weight: 400;
    font-size: 14px;
}
table td b {
    font-weight: 500;
    font-size: 14px;
}
.show_tab {
    display: none;
}
.button_mob {
    min-width: 60px;
    margin: 0;
    padding: 10px;
    font-size: 10px;
}
table.border td.min-width {
    min-width: 280px;
}
.img_mob {
    width: 45px;
    height: 45px;
    cursor: pointer;
}
.down {
    transform: rotate(180deg);
}
span.show_tab {
    display: inline;
}

.creditinput{
    width: 30px;
}

.width{
    width:150px;
}
.checkbox-container {
    display: flex;
    align-items: center;
}

.checkbox-container span {
    margin-left: 8px;
}
.checkbox-container-inner{
    display: flex;
    align-items: center;
    position: relative;
    left:20px;
}
@media only screen and (min-width: 37.5em) {
    table.full_width {
        width: 100%;
    }
    .hide_tab {
        display: none;
    }
    .show_tab {
        display: table-cell;
    }
    .bg_color_mob, th {
        background:  #4d4c4c;
        text-align: center;
    } 
    .img_mob {
        width: 45px;
        height: 45px;
        cursor: pointer;
    }
    .down {
        transform: rotate(180deg);
    }
}

@media only screen and (min-width: 64em) {
    table td input.small,
    table td select.small,
    table td textarea.small,
    table td input.medium,
    table td select.medium,
    table td textarea.medium,
    table td input.large,
    table td select.large,
    table td textarea.large,
    table td textarea.x-large,
    table td select.x-large,
    table td input.x-large {
        min-width: 100%;
    }
}