.employee .app_form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    width: 100%;
}
.employee .form_wrapper {
    margin-bottom: 50px;
}
.employee .app_form .field_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
    width: 100%;
    max-width: 600px;
}
.employee .field_wrapper {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
}
.employee .field_wrapper input,
.employee .field_wrapper select,
.employee .field_wrapper textarea {
    border-radius: 5px;
    font-weight: normal;
    width: 100%;
}
.employee .field_wrapper input,
.employee .field_wrapper select,
.employee .field_wrapper textarea {
    background: #fff3;
    border: 2px solid transparent;
    color: #efeff1;
    padding: 8px 10px;
    font-size: 14px;
}
.employee .field_wrapper input:focus,
.employee .field_wrapper select:focus,
.employee .field_wrapper textarea:focus {
    background: #000;
    border: 2px solid #00bceb;
    outline: transparent;
}
.employee .field_wrapper button {
    background: #6953d2;
    border: transparent;
    border-radius: 5px;
    color: #fff;
    padding: 10px;
    text-transform: uppercase;
    width: 100%;
}

.error_msg {
    display: block;
    color: red;
    font-size: smaller;
    margin: 5px 0 0;
}
.field > select {
    height: auto;
}

@media only screen and (min-width: 37.5em) {
    .employee .app_form .field_wrapper {
        grid-template-columns: 160px auto;
        align-items: center;
    }
    .employee .app_form.otp .field_wrapper {
        grid-template-columns: auto;

    }
    .employee .field_wrapper input,
    .employee .field_wrapper select,
    .employee .field_wrapper textarea,
    .employee .field_wrapper button {
        width: 400px;
    }

}