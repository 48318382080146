.pwd_reset{
    margin-bottom: 30px;
}
.field_wrapper{
    margin-bottom: 20px;
}
.field_wrapper .input_wrapper{
    position: relative;
    margin: 8px 0;
}
.field_wrapper input {
    position: relative;
    background: transparent;
    border: 2px solid #4d4c4c;
    border-radius: 4px;
    color: #dce0e6;
    font-size: 14px;
    height: 40px;
    outline: none;
    padding: 10px 12px;
    width: 250px;
}
.field_wrapper .toggle_pwd {
    height: 24px;
    position: absolute;
    left: 215px;
    top: 8px;
    width: 24px;
}
.field_wrapper .toggle_pwd svg {
    cursor: pointer;
    fill: #4d4c4c;
}
.toggle-pwd svg.show,
.field_wrapper.show-pwd .toggle-pwd svg.hide {
    display: block;
}
.field_wrapper.show-pwd .toggle-pwd svg.show {
    display: none;
}
.login_error {
    color: red;
    display: block;
    font-size: 12px;
    margin-top: 10px;
}