.entry .search-wrap {
    display: grid;
    gap: 20px;
    grid-template-columns: 150px 150px auto 150px;
    margin: 20 0;
}
.entry .search-results {
    padding: 30px;
}
.entry .tabs {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    justify-content: flex-start;
}
.entry .tab {
    align-self: flex-end;
    background: #1d1e23;
    border: 1px solid #4d4c4c;
    border-bottom: none;
    cursor: pointer;
    padding: 15px 30px;
}
.entry .tab.active {
    border-top: 3px solid #dc3e58;
    padding: 20px 30px;
    z-index: 1;
}
.entry .tab-panel {
    border: 1px solid #4d4c4c;
    display: none;
    padding: 10px;
    position: relative;
    top: -1px;
    width: 100%;
}
.entry .tab-panel.active {
    display: block;
}