/* Styles for mobile view (Small = 600 to 767 pixels) */
@media only screen and (max-width: 47.9375em) {

  /* Styles for res-tporders-mobile */
  .home_mob {
    width: 100%;
  }

  .home_mob th:nth-child(4),
  .home_mob td:nth-child(4),
  .home_mob th:nth-child(6),
  .home_mob td:nth-child(6),
  .home_mob th:nth-child(7),
  .home_mob td:nth-child(7),
  .home_mob th:nth-child(8),
  .home_mob td:nth-child(8),
  .home_mob th:nth-child(9),
  .home_mob td:nth-child(9) {
    display: none;
  }

  .header .banner1 .logo {
    align-items: center;
    display: inline-flex;
    height: 50px;
    justify-content: center;
    padding: 10px 0;
    vertical-align: top;
    width: calc(100% - 55px);
    margin-top: 10px;
  }

  .btn {
    border-radius: 100px;
    cursor: pointer;
    display: block;
    font-size: 13px;
    line-height: 1.25em;
    margin-right: 20px;
    min-width: 140px;
    padding: 10px;
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }
}

/* Styles for tablet view (768 to 1023 pixels) */
@media only screen and (min-width: 48em) and (max-width: 63.9375em) {


  .home_mob th:nth-child(5),
  .home_mob td:nth-child(5),
  .home_mob th:nth-child(6),
  .home_mob td:nth-child(6),
  .home_mob th:nth-child(7),
  .home_mob td:nth-child(7),
  .home_mob th:nth-child(9),
  .home_mob td:nth-child(9) {
    display: none;
  }

  .header .banner1 .logo {
    align-items: center;
    display: inline-flex;
    height: 50px;
    justify-content: center;
    padding: 10px 0;
    vertical-align: top;
    width: calc(100% - 55px);
    margin-top: 10px;
  }
}